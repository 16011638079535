<template>
  <section>
    <b-row>
      <b-col class="text-center">
        <h3 class="my-3">
          Authorization Lazada
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        sm="4"
        md="4"
        lg="4"
        offset-lg="2"
        class="mx-auto"
      >
        <b-card
          border
          border-variant="primary"
          align="center"
        >
          <b-card-body>
            <b-avatar
              size="5rem"
              :src="$iconMarketplace('lazada')"
              variant="light"
              square
              class="b-avatar-transparent"
            />
            <b-card-text
              v-if="!flagProcess"
              class="mt-2"
            >
              Sedang Menghubungkan Akun Anda...
            </b-card-text>
            <b-card-text
              v-if="flagProcess"
              class="mt-2"
            >
              Mencocokkan Data...
            </b-card-text>
            <!-- <b-card-text class="mt-2">
              Integrasi Berhasil (res.message)
            </b-card-text> -->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAvatar, BCard, BCardText, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import {
  ref, onMounted, computed, watchEffect,
} from '@vue/composition-api'
import Swal from 'sweetalert2'

/* eslint-disable global-require */
export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardBody,
  },

  setup(_, { root }) {
    const flagProcess = ref(false)
    onMounted(() => {
      const queries = root.$route.query
      if (queries.code) {
        const payload = {
          // shopId: queries.shop_id,
          code: queries.code,
        }
        root.$store.dispatch('lazada/getToken', payload)
      }
    })

    const statusLogin = computed(() => root.$store.state.lazada.loginFlag)

    watchEffect(() => {
      const status = statusLogin.value
      if (status) {
        flagProcess.value = true
        root.$router.push({ name: 'integration' }).catch(() => {})
      }
    })

    return {
      flagProcess,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
</style>
